// importamos sentry con un import dinamico para generar un bundle aparte
const Sentry = () => import(/* webpackChunkName: "sentry" */ '@sentry/nextjs');

const init = (options) => {
  Sentry().then((sentry) => sentry.init(options));
};

const setUser = (user) => {
  Sentry().then((sentry) => sentry.setUser(user));
};

const captureException = (exception, captureContext) => {
  Sentry().then((sentry) => sentry.captureException(exception, captureContext));
};

const flush = (number) => {
  Sentry().then((sentry) => sentry.flush(number));
};

module.exports = {
  init,
  setUser,
  captureException,
  flush
};
