import brands from '@/brands';

const getStore = (url) => {
  const filtrado = url?.split('/')?.[1];
  if (brands(filtrado)) return filtrado;
  return process.env.NEXT_PUBLIC_STORE_ID;
};

// eslint-disable-next-line import/prefer-default-export
export { getStore };
